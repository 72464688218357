//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  fetchOnServer: false,
  data: () => ({
    global: [],
    subemailError: [],
    successSubscribe: "",
    errorSubscribe: "",
    errorProduct: "",
    subemail: "",
    dismissCountDown: 0,
    showDismissibleAlert: false,
    products: [],
    productName:''
  }),

  //Fetching Global Data
  async fetch() {
    try {
      this.global = await this.$strapi.find("global");


    this.$strapi.graphql({
        query: `query{
              products{
                id,
                name,
                sortOrder,
                image{
                 url
                },
                features{
                  makeYear
                }
              }
            }`
      }).then((res)=>{
        this.products = res.products
        .map((item) => item)
        .sort((a, b) => a.sortOrder - b.sortOrder);
      })


    } catch (error) {
      console.error(error);
    }
  },
  methods: {
    validateSubmail() {
      this.subemailError = [];
      if (!this.subemail) {
        this.subemailError.push("Email required.");
      } else if (!this.validSubEmail(this.subemail)) {
        this.subemailError.push("Valid email required.");
      }
    },

    //Email validation
    validSubEmail: function (subemail) {
      var re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(subemail);
    },

    //Mail function
    async subscribe() {

      if (this.productName == "") {
        this.errorProduct = "Please Select A vehicle"
        this.dismissCountDown = 2;
      }else if( !this.validSubEmail(this.subemail) ){
        this.errorSubscribe = "Please Enter a Valid Email";
        this.subemail = "";
        this.successSubscribe = "";
        this.dismissCountDown = 2;
      } else {
        const mailX = await this.$strapi.create("emails", {
          email: process.env.SMTP_MAIL_TO,
          from: "Enquiry",
          subject: "Subscription Notification:" + this.productName,
          html:
            "<p> Please find the New Subscriber Details </p><p> Email : " +
            this.subemail +"<p>Vehicle : <b>" + this.productName + "</b></p>"+
            "</p></p><br><br><p>Thanks and Regards - ForexMotors</p>",
        });

        if (mailX) {
          this.$swal({
            icon: "success",
            title: "You are subscribed Forex Motors",
            showConfirmButton: false,
            customClass: {
              confirmButton: "sweet-alert-button",
            },
            timer: 1500,
          });

          this.errorSubscribe = "";
          this.subemail = "";
          this.productName = "";

        }
      }
    },
  },
};
