//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getCountries } from "../utils/countries";

export default {
  fetchOnServer: false,
  data() {
    return {
      scTimer: 0,
      scY: 0,
      modalShow: false,
      email: '',
      phone: '',
      country: '',
      productName: [],
      emailError: [],
      phoneError: [],
      products: [],
      vehicleNameError: [],
      countryError: [],
      btnDisabled: false,
      countryDetails: []
    };
  },

  async fetch(){
    this.$strapi.graphql({
      query: `query{
            products{
              id,
              name,
              sortOrder,
              image{
                url
              },
              features{
                makeYear
              }
            }
          }`
    }).then((res)=>{
        this.products = res.products.map((item) => item).sort((a, b) => a.sortOrder - b.sortOrder);
    })

    this.countryDetails = getCountries();
  },

  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },

  methods: {
    handleScroll: function () {
      if (this.scTimer) return;
      this.scTimer = setTimeout(() => {
        this.scY = window.scrollY;
        clearTimeout(this.scTimer);
        this.scTimer = 0;
      }, 100);
    },
    toTop: function () {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
    validate() {
      this.emailError = [];
      this.phoneError = [];
      this.messageError = [];
      this.countryError = [];
      this.vehicleNameError = [];

      if (!this.email) {
        this.emailError.push("Email required.");
      } else if (!this.validEmail(this.email)) {
        this.emailError.push("Valid email required.");
      }
      if (!this.phone) {
        this.phoneError.push("Phone Number required.");
      } else if (!this.validPhone(this.phone)) {
        this.phoneError.push("Valid Phone Number required.");
      }

      if (this.productName.length == 0) {
        this.vehicleNameError.push("Vehicle Required");
      }
      if (!this.country) {
        this.countryError.push("Country Required");
      }

    },
     //Email validation
    validEmail: function (email) {
      var re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    //Phone number validation
    validPhone: function (phone) {
      var re =
        /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/;
      return re.test(phone);
    },
     async send() {
      this.validate();
      this.successMessage = [];
      this.errorMessage = [];
      let mailsubject = "";

      if (this.subject != "") {
        mailsubject = this.subject;
      } else {
        mailsubject = "Contact form Enquiry";
      }

      try {
        if (this.email && this.phone && this.productName.length > 0) {
          this.btnDisabled = true;
          const mailX = await this.$strapi.create("emails", {
            email: process.env.SMTP_MAIL_TO,
            from: "Sales Enquiry",
            subject: "Quick Enquiry",
            html:
              "<p> Please find the Enquiry Details </p><p> Email : " +
              this.email +
               "</p><p> Country : " +
              this.country +
              "</p> <p> Phone : " +
              this.phone +
              "</p><p> Vehicles : " +
              this.productName.toString() +
              "</p></p><br><p>Thanks and Regards - Forex Motors</p>",
          });

          if (mailX) {
            this.errorMessage = [""];
            this.btnDisabled = false;
            this.modalShow =false

            // this.$swal(
            //   "Thank You",
            //   "Thank You for contacting us, our team will revert back soon.",
            //   "OK"
            // );
            // this.successMessage.push("Message Sent Ok!");
            this.name = "";
            this.email = "";
            this.phone = "";
            this.productName = []

            this.$router.push({ name: 'thankyou' })

          }
        } else {
          this.successMessage.push("Something happend please try again!!");
        }
      } catch (error) {

      }
    },

  },
};
