export const HaveAnyQue = () => import('../../components/HaveAnyQue.vue' /* webpackChunkName: "components/have-any-que" */).then(c => wrapFunctional(c.default || c))
export const Page = () => import('../../components/Page.vue' /* webpackChunkName: "components/page" */).then(c => wrapFunctional(c.default || c))
export const PageHaveAnyQue = () => import('../../components/PageHaveAnyQue.vue' /* webpackChunkName: "components/page-have-any-que" */).then(c => wrapFunctional(c.default || c))
export const Seo = () => import('../../components/Seo.vue' /* webpackChunkName: "components/seo" */).then(c => wrapFunctional(c.default || c))
export const TheFooter = () => import('../../components/TheFooter.vue' /* webpackChunkName: "components/the-footer" */).then(c => wrapFunctional(c.default || c))
export const TheHeader = () => import('../../components/TheHeader.vue' /* webpackChunkName: "components/the-header" */).then(c => wrapFunctional(c.default || c))
export const ProductsProductCard = () => import('../../components/products/ProductCard.vue' /* webpackChunkName: "components/products-product-card" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
