import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _f12b1702 = () => interopDefault(import('../pages/about/index.vue' /* webpackChunkName: "pages/about/index" */))
const _135acfa1 = () => interopDefault(import('../pages/articles/index.vue' /* webpackChunkName: "pages/articles/index" */))
const _1cd9ef92 = () => interopDefault(import('../pages/contact/index.vue' /* webpackChunkName: "pages/contact/index" */))
const _5cce6c44 = () => interopDefault(import('../pages/gallery/index.vue' /* webpackChunkName: "pages/gallery/index" */))
const _75cc660c = () => interopDefault(import('../pages/privacy/index.vue' /* webpackChunkName: "pages/privacy/index" */))
const _fad6cefc = () => interopDefault(import('../pages/services/index.vue' /* webpackChunkName: "pages/services/index" */))
const _5d382c0e = () => interopDefault(import('../pages/terms/index.vue' /* webpackChunkName: "pages/terms/index" */))
const _53ac5050 = () => interopDefault(import('../pages/testimonials/index.vue' /* webpackChunkName: "pages/testimonials/index" */))
const _8a55d354 = () => interopDefault(import('../pages/thankyou.vue' /* webpackChunkName: "pages/thankyou" */))
const _3a1608c4 = () => interopDefault(import('../pages/vehicle/index.vue' /* webpackChunkName: "pages/vehicle/index" */))
const _11a42e59 = () => interopDefault(import('../pages/articles/_slug.vue' /* webpackChunkName: "pages/articles/_slug" */))
const _3d834b54 = () => interopDefault(import('../pages/vehicle/_slug.vue' /* webpackChunkName: "pages/vehicle/_slug" */))
const _5625bf27 = () => interopDefault(import('../pages/vehiclecategory/_category.vue' /* webpackChunkName: "pages/vehiclecategory/_category" */))
const _63eef5c1 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about",
    component: _f12b1702,
    name: "about"
  }, {
    path: "/articles",
    component: _135acfa1,
    name: "articles"
  }, {
    path: "/contact",
    component: _1cd9ef92,
    name: "contact"
  }, {
    path: "/gallery",
    component: _5cce6c44,
    name: "gallery"
  }, {
    path: "/privacy",
    component: _75cc660c,
    name: "privacy"
  }, {
    path: "/services",
    component: _fad6cefc,
    name: "services"
  }, {
    path: "/terms",
    component: _5d382c0e,
    name: "terms"
  }, {
    path: "/testimonials",
    component: _53ac5050,
    name: "testimonials"
  }, {
    path: "/thankyou",
    component: _8a55d354,
    name: "thankyou"
  }, {
    path: "/vehicle",
    component: _3a1608c4,
    name: "vehicle"
  }, {
    path: "/articles/:slug",
    component: _11a42e59,
    name: "articles-slug"
  }, {
    path: "/vehicle/:slug",
    component: _3d834b54,
    name: "vehicle-slug"
  }, {
    path: "/vehiclecategory/:category?",
    component: _5625bf27,
    name: "vehiclecategory-category"
  }, {
    path: "/",
    component: _63eef5c1,
    name: "index"
  }, {
    path: "/",
    component: _63eef5c1,
    name: "home"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
